// All dark themes
$input-group-addon-color: #222;
$input-disabled-bg: #111;
$alert-bg-scale: 70%;
$secondary: #222;
$table-striped-bg: rgba(#fff, 0.05);

$es-choices-highlight-color: #000;

$invert-color-value: 1 !default;

/* Invert the clock icon color for Chrome */
input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert($invert-color-value);
}
